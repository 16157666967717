.Contact {
    height: 100vh;
    width: 100%;
    background: linear-gradient(339deg, rgb(255, 0, 0) 0%, rgb(255, 136, 0) 100%);
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-block{
    text-decoration: none;
    color: floralwhite;
    font-size: calc(16px + 2vmin);
    margin: 32px;
    border-radius: 64px;
    border: dotted 4px white;
    padding: 16px;
    padding-left: 32px;
    padding-right: 32px;
}

.coloured {
    color:darkblue;
}