@use "./base" as base-style;


body, html {
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}

.main {
  scroll-snap-type: y mandatory;
}

.App {
  text-align: center;

}

.Footer {
  height: 15vh;
  width: 100%;
  background-color: #0e0e0e;
  display: flex;
  justify-content: left;
  align-items: center;
  color:floralwhite;
}




//::-webkit-scrollbar {
//  width: 10px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  background: #f1f1f1;
//
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background: #888;
//  border-radius: 16px;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: #555;
//}

::-webkit-scrollbar {
  display: none;
}

.footer-socials {
  display: flex;
  margin-left: 4em;
  margin-right: auto;
  transition: 0.5s;
}

.Footer h3 {
  margin-right: 4em;
  transition: 0.5s;
  font-weight: 200;
}

.footer-social-element {
  text-decoration: inherit;
  color: inherit;
  width: 48px;
  height: 32px;
  margin: 4px;
  background-size:cover;
  transition-duration: 0.05s;
  transition-timing-function: linear;
}

.footer-social-element:hover {
  transform: translate(0px,-4px);

}

.footer-github {
  @extend .footer-social-element;
  background: url("./res/github.svg") no-repeat center;

}
.footer-linkedin {
  @extend .footer-social-element;
  background: url("./res/linkedin.svg") no-repeat center;

}

.footer-twitter {
  @extend .footer-social-element;
  background: url("./res/x.svg") no-repeat center;

}

.footer-soundcloud {
  @extend .footer-social-element;
  background: url("./res/soundcloud.svg") no-repeat center;
}

.footer-itchio {
  @extend .footer-social-element;
  background: url("./res/itchio.svg") no-repeat center;
}

/* Media's */

@media only screen and (max-width: base-style.$small-monitor-width) {



}

@media only screen and (max-width: base-style.$tablet-width) {



}

@media only screen and (max-width: base-style.$phone-width) {

  .Footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;


  }
  .footer-socials {
    margin-right: inherit;
    margin-left: inherit;
  }

  .Footer h3 {
    margin-right: inherit;
    margin-top: 4px;
  }

}
