.modal-base {
    background: radial-gradient( rgb(41, 41, 41),rgb(21, 21, 21));
    border-radius: 16px;
    box-shadow: 0 8px 4px 0 rgba(0, 0, 0, 0.2), 0 0 16px 0 rgba(0, 0, 0, 0.19);
}

.modal-quit-button {
    filter: invert(1);
}

.project-modal-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: floralwhite;
}

.project-modal-image {
    max-width: 700px;
    max-height: 300px;
    object-fit: cover;
    margin: 16px;
    border-radius: 16px;
}

.project-modal-title {
    margin-top: 72px;
    font-size: calc(16px + 3vmin);
    margin-bottom: 8px;
}