.page-not-found {

  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: rgb(21, 21, 21);
  justify-content: center;
  align-items: center;
  color: floralwhite;

  font-size: calc(10px + 2vw);
}
