.cartridge-rack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 128px;
}
.cartridge-container {
  width: 750px;
  height: 750px;
}