@use "../../base" as base-style;

.Projects {
    height: auto;
    width: 100%;
    background: radial-gradient( rgb(41, 41, 41),rgb(21, 21, 21));
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    padding-top: 128px;
    padding-bottom: 0px;
}

.projects-title {
    font-size: calc(10px + 6vmin);
    color: floralwhite;
}


.canvas-container {

    width: 100vw;
    height: 90vh;
    margin-top: -172px;

}


/* Media's */

@media only screen and (max-width: base-style.$small-monitor-width) {

}

@media only screen and (max-width: base-style.$tablet-width) {

    .projects-title {
        font-size: 15vmin;
    }

}

@media only screen and (max-width: base-style.$phone-width) {
    .canvas-container {

        height: 180vh;
        margin-top: -128px;
        margin-bottom: -256px;
        overflow: visible;
    }

}