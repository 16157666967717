.FancyButton {

    border-radius: 8px;
    border-style:solid;
    border-color: #fff;
    border-width: 2px;
    padding: 5px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 8px;
    font-size: 24px;
    text-decoration: inherit;
    color: inherit;

    transition-duration: 0.05s;
    transition-timing-function: linear;

    position: relative;

    overflow: hidden;
     
}


.FancyButton:hover {
    font-weight: 500;
    transition: color 0.5s;
    transition-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);
    color: #181818;
}

.fancy-dot {
    border-radius: 100%;
    width: 0px;
    height: 0px;
    position: absolute;
    background-color: #fff;
    transition: width 0.5s, height 0.5s;
    transition-timing-function:cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate(-75px,-75px);
    
    z-index: 1;
}

.FancyButton:hover .fancy-dot {
    width: 150px;
    height: 150px;
}

.content {
    position: absolute;
    z-index: 2;
    top:0px;
    left: 0px;
    right:0px;
    bottom: 0px;
}
