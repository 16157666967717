@use "../../base" as base-style;

/* Base element styles */

.avatar-container {
    width: 800px;
    height: 100vh;
  
    position: absolute;
    right: 5vw;
    top:  0px;
  
  }

  .home-content {
    width: calc(100vw - 50vw);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-box {
    padding: 32px;
    width: auto;

  }

  .content-box::content {
    filter: none;
  }

  .Home {
    background: rgb(34,189,195);
    background: linear-gradient(339deg, rgba(34,189,195,1) 0%, rgba(253,45,199,1) 100%);
    color: floralwhite;
    scroll-snap-align: start;
    overflow: hidden;
  }

.quick-nav {
    display: flex;
    justify-content: left;
    animation: 1.55s cubic-bezier(0.34, 1.56, 0.64, 1) 0.0s 1 slideInFromLeft;
}

 .title-letter {
  transition-duration: 0.05s;
  transition-timing-function: linear;
 }

.title-letter:hover {
  transform: translate(0px,-4px)
}

//.qnav-element {
//
//    border-radius: 8px;
//    border-style:solid;
//    border-color: #fff;
//    border-width: 2px;
//    padding: 5px;
//    padding-left: 16px;
//    padding-right: 16px;
//    margin: 8px;
//    font-size: 24px;
//    text-decoration: inherit;
//    color: inherit;
//    width: 100px;
//
//    transition-duration: 0.05s;
//    transition-timing-function: linear;
//
//}

.qnav-element:hover {
    font-weight: 500;
}

.day {
    color: #FF8000;
    transition-duration: 0.05s;
    transition-timing-function: linear;
}

.heart {
    color: #eb0800;
    transition-duration: 0.05s;
    transition-timing-function: linear;
}

.day:hover {
    font-weight: 500;
}
    
.heart:hover {
    font-weight: 500;
}
    
.catch-paragraphs {
  display: flex;
  flex-direction: column;
}

.catch-paragraph {
    font-size: calc(8px + 6vmin);
    font-weight: 300;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 4px;
}

.title {
    display: flex;
    justify-items: left;
    font-weight: 500;
    font-size: calc(32px + 8vmin);
    animation: 1s cubic-bezier(0.34, 1.56, 0.64, 1) 0s 1 slideInFromLeft;
}

.creator-para {
  @extend .catch-paragraph;
  animation: 1.25s cubic-bezier(0.34, 1.56, 0.64, 1) 0.0s 1 slideInFromLeft;
}

.tinkerer-para {
  @extend .catch-paragraph;
  animation: 1.35s cubic-bezier(0.34, 1.56, 0.64, 1) 0.0s 1 slideInFromLeft;
}

.socials {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  animation: 1.45s cubic-bezier(0.34, 1.56, 0.64, 1) 0.0s 1 slideInFromLeft;
}

.social-element {
  text-decoration: inherit;
  color: inherit;
  width: 48px;
  height: 32px;
  margin: 4px;
  background-size:cover;
  transition-duration: 0.05s;
  transition-timing-function: linear;
}

.social-element:hover {
  transform: translate(0px,-4px);
  
}

.github {
  @extend .social-element;
  background: url("../../res/github.svg") no-repeat center;
  
}
.github:hover {
  filter: invert(80%);
}

.linkedin {
  @extend .social-element;
  background: url("../../res/linkedin.svg") no-repeat center;
  
}

.linkedin:hover {
  filter: invert(80%) sepia(94%) saturate(4356%) hue-rotate(221deg) brightness(98%) contrast(98%);
}

.twitter {
  @extend .social-element;
  background: url("../../res/x.svg") no-repeat center;
  
}

.twitter:hover {
  filter: invert(80%);
}

.soundcloud {
  @extend .social-element;
  background: url("../../res/soundcloud.svg") no-repeat center;
}

.soundcloud:hover {
  filter: invert(63%) sepia(83%) saturate(3877%) hue-rotate(6deg) brightness(103%) contrast(98%);
}

.itchio {
  @extend .social-element;
  background: url("../../res/itchio.svg") no-repeat center;
}

.itchio:hover {
  filter: invert(65%) sepia(63%) saturate(6071%) hue-rotate(4deg) brightness(96%) contrast(103%);
}

.fancy-button {
  width: 100px;
  height: 28px;;
}

  /* Media's */

  @media only screen and (max-width: base-style.$small-monitor-width) {
    

    .avatar-container {
        right: 0%;
      transition: base-style.$resize-length;
      }

      .home-content {
        width: calc(100vw - 600px);
        transition: base-style.$resize-length;
      }

  }

  @media only screen and (max-width: base-style.$tablet-width) {
    

    .avatar-container {
        right: 0%;
        width: 40%;
      transition: base-style.$resize-length;
    }

    .home-content {
        width: calc(100vw - 40%);
      transition: base-style.$resize-length;
      }

  }

  @media only screen and (max-width: base-style.$phone-width) {
    

    .avatar-container {
        display: none;
      transition: base-style.$resize-length;
    }

    .content-box {
      width: fit-content;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: base-style.$resize-length;
    }

    .home-content {
        width: 100%;
        justify-content: center;
        align-items: center;
      flex-shrink: 1s;
      margin-top: 32px;
      transition: base-style.$resize-length;
      }

    .catch-paragraphs {
      justify-content: center;
      align-items: center;
      transition: base-style.$resize-length;
    }

    .quick-nav {
      margin-top: 32px;
      flex-direction: column;
      transition: base-style.$resize-length;
    }

    .fancy-button {
      width: calc(66vw);
      transition: base-style.$resize-length;
    }

  }



  /* Animations */

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  

 