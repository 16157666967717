@use "../../base" as base-style;

.About {
    height: auto;
    width: 100%;
    background: linear-gradient(339deg, rgb(76, 0, 199) 0%, rgb(34,189,195,1) 100%);
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: base-style.$resize-length;
    padding-top: 256px;
    padding-bottom: 256px;
}

.writeup-container {
    overflow: visible;
    color: floralwhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    margin-right: 7.5vw;
    width: calc(33%);
    transition: base-style.$resize-length;
    background: rgba(21, 21, 21,0.5);
    padding: 32px;
    border-radius: 16px;
}

.writeup-title {
    font-size: calc(32px + 4vmin);
  transition: base-style.$resize-length;
  padding-top: 0px;
}

.writeup-content {
    font-size: calc(10px + 1vmin);
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  transition: base-style.$resize-length;
}
.writeup-separator {
    height: 4px;
    margin: 16px;
    width: calc(25%);
    background-color: floralwhite;
    margin: 8px;
    border-radius: 16px;
  transition: base-style.$resize-length;
}

.picture-container {
  --box-size: 750px;
  width: var(--box-size);
  height: var(--box-size);
  display: grid;
  grid-template-columns: repeat(3, calc(var(--box-size) / 3));
  gap: 16px;
  grid-template-rows: repeat(3, calc(var(--box-size) / 3));
  margin-left: 10%;
  margin-right: auto;
  transition: base-style.$resize-length;
}

.writeup-content-header {
    font-size: calc(32px + 2vmin);
  font-weight: bold;
    text-align: left;
    margin: 32px;
   width: 100%;
  transition: base-style.$resize-length;
}

.writeup-content .block {
    margin: 8px;
    transition: base-style.$resize-length;
  font-size: calc(1.75vmin);
}

 .about-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    animation: Anim_Bob 4s ease-in-out 0s infinite alternate;
   transition: base-style.$resize-length;
 }

@for $i from 1 through 4 {
  .about-picture:nth-child(#{$i}) {
    animation-delay: calc(0.0s + (1s * #{$i}));
  }
}

.about-picture:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1 / 3;
}

.about-picture:nth-child(3) {
  grid-column: 1 / 3;
}

.about-picture:nth-child(4) {
  grid-row: 2 / 4;
}

 .spacer {
    width: 35%;
    height: 100%;
 }

 @keyframes Anim_Bob {
    0% {transform: translate(0px, 0px);}
   50% {transform: translate(-4px, -4px);}
    100% {transform: translate(-4px, 0px);}
  }

/* Media's */

@media only screen and (max-width: base-style.$small-monitor-width) {

  .picture-container {
    --box-size: 650px;
    margin-left: 5vw;
  }

  .writeup-container {
    width: calc(40%);
    margin-right: 5vw;
  }

  .writeup-title {
    display: none;
  }

  .writeup-separator {
    display: none;
  }
}

@media only screen and (max-width: base-style.$tablet-width) {

  .picture-container {
    --box-size: 550px;
    margin-left: 2.5vw;
  }

  .writeup-container {
    margin-right: 2.5vw;
    width: calc(45%);
  }

  .writeup-content .block {
    font-size: calc(1.75vmin);
  }
}

@media only screen and (max-width: base-style.$phone-width) {

  .picture-container {
    --box-size: calc(100vw - 128px);
    margin: 32px;
  }

  .writeup-container {
    margin: 32px;
    width: calc(100vw - 64px);
    border-radius: 0px;
  }

  .About {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 128px;
    padding-bottom: 128px;
  }

  .writeup-content .block {
    font-size: calc(4vmin);
  }

  .writeup-title {
    font-size: calc(8vmin);
  }

}