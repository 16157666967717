.modal-canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 4;
  transition: 1s cubic-bezier(0.83, 0, 0.17, 1);
}

.modal-base {
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  transition: 1s cubic-bezier(0.83, 0, 0.17, 1);
  z-index: 5;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal-base::-webkit-scrollbar {
  display: none;
}

.modal-quit-button {
  border: none;

  position: absolute;
  right: 16px;
  top: 16px;
  background-color: transparent;
  background: url("../../res/exit-icon.svg") no-repeat center;
  transition: 1s cubic-bezier(0.83, 0, 0.17, 1);
  pointer-events: bounding-box;
}

.modal-quit-button:hover {

  cursor: pointer;

}