

.Divider {
    height: 5vh;
    width: 100%;
    display: flex;
    background-color: #fff;

}

#Wave-divider-1 {
    transform:rotate(180deg) translate(0px, 99%);
    top:inherit;
    bottom: inherit;
}

#Wave-divider-2 {
    transform:translate(0px, 49%);
    top:inherit;
    bottom: inherit;
  }