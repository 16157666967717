@use "../../base" as base-style;


.project-page {
  background: radial-gradient( rgb(41, 41, 41),rgb(21, 21, 21));
  min-height: 100vh;
  min-width: 100vw;
   display: flex;
  flex-direction: column;
  align-items: center;
  cursor: inherit;
}

.project-banner {
  height: 200px;
  width: 100%;
  object-fit: cover;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.51);
}

.project-title {
  color: floralwhite;
  font-size: 4vw;
  //margin-right:auto ;
  margin-bottom: inherit;
  text-align: left;
  margin-top: inherit;

  width: auto;
}

.writeup-block {
  max-width: 1000px;
  font-size: calc(1.25vw);
  color: floralwhite;
  //margin-left: 64px;
  align-self: flex-start;
  justify-self: flex-start;
  margin-top: 0.5em;
  text-align: left;
}

.project-header {
  display: flex;
  justify-items: center;
  width: calc(80%);
  gap: 2em;
  margin-top: 1em;
  margin-bottom: 2em;
}

.picture-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, calc(80% / 3));
  grid-gap: 1em;
  justify-items: center;
  //margin-bottom: 16px;
}

.picture-grid-item {

  border-radius: 1em;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.back-button {
  position: fixed;
  top: 1em;
  left: 1em;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  border: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.51);
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button-icon {
  width: 64px;
  height: 64px;
  background: url("../../res/arrow-left.svg") no-repeat center;
}

.back-button-icon:hover {
  cursor: pointer;
}

.project-section {
  width: 80%;
  margin-bottom: 64px;
}

.project-section-title-container {

  display: flex;
  flex-direction: column;
  width: 100%;

}

.project-section-title {
  color: floralwhite;
  font-size: 2.5vw;
  margin-right:auto;
  margin-bottom: 0px;
}

.project-tags {
  width: 30%;
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
}

.project-tag {
  padding-left: 1em;
  padding-right: 1em;
  border: solid 2px;
  border-radius: 1em;
  margin-left: 0.5vmin;
  margin-right: 0.5vmin;
  height: calc(1.95vmin);
  font-size: calc(1.1vmin);
  align-content: center;
  margin-bottom: 0.5em;
}

.stack {
  display: flex;
  flex-direction: column;
}
.project-languages {
  display: flex;
  //margin-bottom: 16px;
  flex-wrap: wrap;
  align-items: center;

}

.label {
  margin-bottom: 0.5em;
  color: floralwhite;
  font-size: calc(2vmin);
  display: flex;
}

.project-language-label {
  @extend .label;
}

.project-picture-label{
  @extend .label;
}

.project-links-label {
  @extend .label;
}

.seperator {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
  background-color: #fff;
  height: 2px;
  //border-bottom-left-radius: 32px;
  //border-top-right-radius: 32px;
  opacity: 0.75;
}

.writeup-stack {
  @extend .stack;
  min-height: 100%;
  margin-left: auto ;
}

.project-title-stack {
  @extend .stack;
  justify-items: center;
  gap: 1em;
  margin-bottom: 1em;
}

.project-title-seperator {
  @extend .seperator;
  width: 100%;
}

project-link::before{
  content: attr(data-content);
}
.project-link {

}

.project-section-content {
  display: flex;
  flex-direction: column;
}

.project-section-text {
  font-size: calc(1.25vw);
  color: floralwhite;
  margin-bottom: 1em;
  text-align: left;
}

.project-section-chunck {
  display: flex;
  flex-direction: column;
}

.section-content-picture-container{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.content-picture {
  margin: 0.5em;
  border-radius: 0.5em;
}

.section-content-video-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
}

.content-video {

}

.project-subheading {
  width: 100%;
}

.project-subheading-title {
  text-align: left;
  color: floralwhite;
  font-size: calc(2.5vmin) ;
}

.subheading-seperator {
  @extend .seperator;
  opacity: 0.25;
  width: 25%;
}

.project-writeup-title {
  color: floralwhite;
  font-size: 4vw;
  margin-left: 0px;
  margin-bottom: inherit;
  text-align: left;
  margin-top: inherit;

  width: auto;
}

.project-header-wrapper {
  width: calc(100% - 32px);
  height: auto;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  background-color: #131313;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.51);
  padding: 16px;
  margin-bottom: 1em;
}

.project-link-container{
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 1em;
}
.project-link-icon {
  width: calc(2vmin);
  height: calc(2vmin);
}

.project-link {
  text-decoration: none;
  color: floralwhite;
  font-size: calc(2vmin);
  text-align: center;
}

/* Media's */

@media only screen and (max-width: 1900px) {

  .project-header {
      flex-direction: column;
    gap: 0px
  }

  .writeup-stack {
    margin-left: inherit;
  }

  .writeup-block {
    max-width: 100%;
    font-size: 1.25vw;
  }

}

@media only screen and (max-width: base-style.$tablet-width) {

  .writeup-block{
    font-size: calc(4vmin);
  }
  .project-header {

  }
  .project-title {
    font-size: calc(10vmin);
  }

  .project-writeup-title {
    font-size: calc(10vmin);
  }


  .project-language-label {
    font-size: calc(6vmin);
  }

  .project-link-icon {
    width: calc(6vmin);
    height: calc(6vmin);
  }

  .project-link {
    font-size: calc(6vmin);
  }

  .project-picture-label{
    font-size: calc(6vmin);
  }

  .project-section-title {
    font-size: calc(8vmin);
    text-align: center;
    margin-right: inherit;
  }

  .project-subheading-title{
    font-size: calc(6vmin);
    text-align: center;
  }

  .project-section-text{
    font-size: calc(4vmin);
  }
  .project-subheading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subheading-seperator {
    width: 50%
  }

  .project-tag {
    height: auto;
    font-size: calc(4vmin);
    text-align: left;
    text-wrap: nowrap;
  }
}

@media only screen and (max-width: base-style.$phone-width) {

  .project-header {

  }

}