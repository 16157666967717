
h1, h2, h3, h4, h5, h6, p, input, button, textarea {

  border: none;
  border-radius: inherit;
  margin: inherit;
  padding: inherit;

}

.Editor-Page {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.page-navigator {
  height: 100%;
  width: 400px;
  background-color: rgb(36, 37, 42);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.51);
  display: flex;
  flex-direction: column;
  position: fixed;
}

.page-nav-button {
  width: 100%;
  height: 64px;
  border: none;
  border-radius: inherit;
  font-size: 24px;
  margin: inherit;
  background-color: rgb(41, 43, 46);
  //background-color: transparent;
  color: white;
}

.page-nav-button:hover {
  background-color: rgb(51, 53, 57);
}

.page-navigator .page-nav-button:last-of-type {
  margin-bottom: auto;
}

.page-content {

  height: calc(100% - 64px);
  width: calc(100% - 64px);

  padding: 32px;

  padding-left: 432px;

  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;

}

.stack {
  display: flex;
  flex-direction: column;

}

.row {
  display: flex;
  flex-direction: row
}

.input-box {
  height: 32px;
  border: solid 2px;
  padding: 6px;
  font-size: 24px;
  margin-bottom: 16px;
}

.title-input {
  @extend .input-box;
  width: 90%;
  margin-left: auto;
}

.writeup-input {
  @extend .input-box;
  height: 200px;

}

.control-buttons {
  justify-self: flex-end;
}

.control-button {
  width: 50%;
  height: 64px;
  font-size: 24px;
}

.export {
  @extend .control-button;
  background-color: rgb(113, 255, 115);
}

.import {
  @extend .control-button;
  background-color: rgb(255, 74, 74);
}

.add-page {
  @extend .page-nav-button;
  background-color: rgb(74, 152, 255);
}

.overview-add {
  width: 48px;
  height: 48px;
  font-size: 32px;
}

.overview-image-add {
  @extend .overview-add;
}

.overview-language-add {
  @extend .overview-add;
}

.overview-link-add {
  @extend .overview-add;
}

.box {
  border: solid 2px;
  // border-radius: 8px;
}

.overview-add:hover {
  background-color: rgb(188, 188, 188);
}

.remove-button-small {
  width: 32px;
  height: 32px;

}

.remove-button-small:hover {
  background-color: rgb(188, 188, 188);
}

.expanding-box {
  @extend .box;
  width: calc(100%  - 32px);
  margin-bottom: 16px;
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex-basis: 0;
  border-style: dashed;
}

.overview-language-box {
  @extend .expanding-box;

}

.overview-link-box {
  @extend .expanding-box;

}

.overview-image-box {
  @extend .expanding-box;
}

.image-box{
  @extend .box;
  height: min-content;
  margin: 4px;
  display: flex;
  align-items: center;
  border-color: #ffffff;
}

h4 {
  font-size: 20px;
}

.input-small {
  @extend .input-box;
  font-size: 16px;
  height: min-content;
  margin-bottom: inherit;
}

.image-url-input {
  @extend .input-small;
}

.image-url-remove {
  @extend .remove-button-small
}

.language-box {
  @extend .box;
  height: min-content;
  padding: 8px;
  border-color: #ffffff;
}

.language-hex-input {
  @extend .input-small;
}
.language-remove {
  @extend .remove-button-small
}

.link-remove {
  @extend .remove-button-small
}

.language-text-input {
  @extend .input-small;
}

.link-text-input {
  @extend .input-small;
}

.language-hex-preview {
  @extend .box;
  width:30px;
  height:30px;

}

.overview-sections-add {
  @extend .overview-add;
}

.overview-sections-box {
  @extend .expanding-box;
  flex-direction: column;
}
.content-add{
  @extend .remove-button-small;
}

.section-content-box{
  @extend .expanding-box;
  flex-direction: column;
  border-style: solid;
}

.content-subheading-input {
  @extend .input-small;
  width: 100%;
}

.content-text-input {
  @extend .input-box;
  margin-top: 0px;
  height: 150px;
  width: 100%;
}

.editor-content-box {
 @extend .expanding-box;
  border: none;
  border-bottom: dashed 2px #444;
}

.section-title-input {
  @extend .input-small;

  width: 100%;

}

.content-text-label {
  margin-bottom: 0px;
  font-size: 20px;
  padding: 0px;
}

.section-remove {
  @extend .remove-button-small;
}

.content-remove {
  @extend .remove-button-small;
}

h4 {
  padding: 0px;
}

h5 {
  margin-bottom: 0px;
  font-size: 20px;
  padding: 0px;
}

.content-image-box {
  @extend .expanding-box;
}

.content-image-add {
  @extend .remove-button-small;
}

.image-width-input {
  @extend .input-small;
  width: 64px;
}

.image-height-input {
  @extend .image-width-input;
}

.section-tag-box {
  @extend .expanding-box;
}

.tag-box {
  @extend .language-box;
}

.tag-text-input {
  @extend .language-text-input;
}

.tag-hex-input {
  @extend .language-hex-input;
}

.tag-hex-preview {
  @extend .language-hex-preview;
}

.tag-remove {
  @extend .language-remove;
}

.tag-add {
  @extend .remove-button-small;
}

.project-expand-minimise-button {
  height: 32px;

  padding: 4px;
}

.title-check {
  @extend .title-input;
  width: 32px;
  height: 32px;
  align-self: center;
  margin: inherit;
}